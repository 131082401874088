(() => {
	$('.tab-stay-slider-section .tab-content-slider').each(function (i, el) {
		// content
		let tabContentSliderClass = 'tab-content-slider-' + i;
		$(this).addClass(tabContentSliderClass);

		// tab
		let tabSliderClass = 'tab-slider-' + i;
		$(this).parent().find('.tab-slider').addClass(tabSliderClass);

		let tabSlider = new Swiper('.' + tabSliderClass, {
			speed: 500,
			slideToClickedSlide: false,
			slidesPerView: 'auto',
			freeMode: {
				enabled: true,
				sticky: true
			}
		});

		// Make the tab fully visible when it is clicked
		tabSlider.on('click', function (event) {
			tabSlider.slideTo(event.clickedIndex);
		});

		let contentSlider = new Swiper('.' + tabContentSliderClass, {
			speed: 500,
			autoHeight: true,
			allowTouchMove: false,
			thumbs: {
				swiper: tabSlider
			}
		});
	});
})();

(() => {
	$('.tab-stay-slider-section .tab-nested-card-slider').each(
		function (i, el) {
			let swiperClass = 'tab-nested-card-slider-' + i;
			$(this).addClass(swiperClass);

			// navigation
			let prevClass = 'tab-nested-card-slider-prev-' + i;
			let nextClass = 'tab-nested-card-slider-next-' + i;
			$(this).parent().find('.swiper-button-prev').addClass(prevClass);
			$(this).parent().find('.swiper-button-next').addClass(nextClass);

			new Swiper('.' + swiperClass, {
				speed: 500,
				slidesPerView: 1,
				centerInsufficientSlides: true,
				navigation: {
					prevEl: '.' + prevClass,
					nextEl: '.' + nextClass
				},
				freeMode: {
					enabled: true,
					sticky: true
				}
			});
		}
	);
})();
