(() => {
	// Event slider
	const swiperInstances = [];
	const swiperOptions = {
		loop: false,
		speed: 500,
		slidesPerView: 1.1,
		spaceBetween: 20,
		watchOverflow: true,
		breakpoints: {
			768: {
				slidesPerView: 2,
				slidesPerGroup: 2,
				spaceBetween: 20
			}
		}
	};

	// Function to initialize Swiper instances if below 992px
	function initializeSwipers() {
		$('.event-listing-slider').each(function (i, el) {
			const swiperClass = 'event-listing-slider-' + i;
			$(this).addClass(swiperClass);

			if ($(window).width() < 992 && !swiperInstances[i]) {
				swiperInstances[i] = new Swiper(
					'.' + swiperClass,
					swiperOptions
				);
			}
		});
	}

	// Function to destroy Swiper instances if 992px or above
	function destroySwipers() {
		swiperInstances.forEach((swiper, i) => {
			if (swiper && $(window).width() >= 992) {
				swiper.destroy(true, true);
				swiperInstances[i] = null; // Clear the reference
			}
		});
	}

	function updateSwipers() {
		swiperInstances.forEach((swiper, i) => {
			if (swiper) {
				swiper.update();
			}
		});
	}

	// Initialize or destroy Swiper on page load
	$(document).ready(() => {
		if ($(window).width() < 992) {
			initializeSwipers();
		} else {
			destroySwipers();
		}
	});

	// Re-check on window resize
	$(window).resize(() => {
		if ($(window).width() < 992) {
			initializeSwipers();
		} else {
			destroySwipers();
		}
	});

	// Function to get query parameters from the URL
	function getQueryParams() {
		const params = new URLSearchParams(window.location.search);
		return {
			hotel: params.get('hotel') || 'all',
			category: params.get('category') || 'all'
		};
	}

	// Function to apply filters based on query parameters
	function applyFiltersFromURL() {
		const { hotel, category } = getQueryParams();

		// Set the hotel dropdown to the selected value from the query
		const hotelDropdown = document.querySelector('.select-selected .text');
		const hotelItem = document.querySelector(
			`.select-items [data-hotel="${hotel}"]`
		);
		if (hotelItem) {
			hotelDropdown.textContent = hotelItem.textContent.trim();
		}

		// Filter cards by `data-hotel` attribute
		document
			.querySelectorAll('.event-listing-slider .swiper-slide')
			.forEach(slide => {
				if (
					hotel === 'all' ||
					slide.getAttribute('data-hotel') === hotel
				) {
					slide.classList.remove('js-hidden');
				} else {
					slide.classList.add('js-hidden');
				}
			});

		// Set the active category item based on the query
		document
			.querySelectorAll('.event-categories-slider .swiper-slide')
			.forEach(item => {
				if (item.getAttribute('data-category') === category) {
					item.classList.add('js-active');
				} else {
					item.classList.remove('js-active');
				}
			});

		// Filter event listings by `data-category` attribute
		document.querySelectorAll('.event-listing').forEach(listing => {
			if (
				category === 'all' ||
				listing.getAttribute('data-category') === category
			) {
				listing.classList.remove('js-hidden');
			} else {
				listing.classList.add('js-hidden');
			}
		});
	}

	// Categories filter click handler
	document
		.querySelectorAll('.event-categories-slider .swiper-slide')
		.forEach(item => {
			item.addEventListener('click', () => {
				const category = item.getAttribute('data-category');
				const listings = document.querySelectorAll('.event-listing');
				const eventListingSection = document.querySelector(
					'.event-listing-section'
				);

				// Update the active category in the UI
				document
					.querySelectorAll('.event-categories-slider .swiper-slide')
					.forEach(li => li.classList.remove('js-active'));
				item.classList.add('js-active');

				// Remove and update the data-category-active attribute
				if (eventListingSection) {
					eventListingSection.removeAttribute('data-category-active');
					eventListingSection.setAttribute(
						'data-category-active',
						category
					);
				}

				// Filter listings by selected category
				listings.forEach(listing => {
					if (
						category === 'all' ||
						listing.getAttribute('data-category') === category
					) {
						listing.classList.remove('js-hidden');
					} else {
						listing.classList.add('js-hidden');
					}
				});
			});
		});

	// Hotel filter click handler
	document.querySelectorAll('.select-items li').forEach(item => {
		item.addEventListener('click', () => {
			const selectedHotel = item.getAttribute('data-hotel');

			// Update the selected hotel in the dropdown
			document.querySelector('.select-selected .text').textContent =
				item.textContent.trim();
			document
				.querySelector('.select-items')
				.classList.remove('js-active');

			// Filter slides by selected hotel
			document
				.querySelectorAll('.event-listing-slider .swiper-slide')
				.forEach(slide => {
					if (
						selectedHotel === 'all' ||
						slide.getAttribute('data-hotel') === selectedHotel
					) {
						slide.classList.add('js-active');
						slide.classList.remove('js-hidden');
					} else {
						slide.classList.add('js-hidden');
						slide.classList.remove('js-active');
					}
				});

			// Reinitialize Swiper sliders
			updateSwipers();
		});
	});

	// Apply initial filters from URL parameters
	applyFiltersFromURL();

	function eventCategoriesSlider() {
		$('.event-categories-slider').each(function (i, el) {
			let swiperClass = 'event-categories-slider-' + i;
			$(this).addClass(swiperClass);

			let tabSlider = new Swiper('.' + swiperClass, {
				speed: 500,
				slidesPerView: 'auto',
				spaceBetween: 0,
				slideToClickedSlide: false,
				freeMode: {
					enabled: true,
					sticky: true
				}
			});

			// Make the tab fully visible when it is clicked
			tabSlider.on('click', function (event) {
				tabSlider.slideTo(event.clickedIndex);
			});
		});
	}
	eventCategoriesSlider();
})();
